<template>
    <v-app-bar flat dark clipped-left class="q-app-bar" :class="{ 'q-app-bar--mobile': isMobile }" height="72px">
        <div class="q-app-bar__content">
            <div class="q-app-bar__left">
                <v-app-bar-nav-icon v-if="isTabletAndDown" :small="isMobile" color="#005851" @click.stop="toggleMobileMenu" />

                <QResourceMenuField id="v-step-1" v-if="hasDivision(['Field'])" />
                <QResourceMenuCorporate id="v-step-1" v-if="hasDivision(['Corporate'])" />
                <QResourceMenuCss id="v-step-1" v-if="hasCcsDivision" />
            </div>

            <v-spacer />
            <v-toolbar-title v-if="!isTabletAndDown" class="nav-logo">
                <router-link to="/" class="nav-logo-link" style="text-decoration: none !important;">
                    <div v-if="isDemoMode" class="nav-logo__demo">HQ Demo</div>
                    <img v-else :src="logoPath" alt="Quility Dash Board" contain />
                </router-link>
            </v-toolbar-title>
            <v-spacer />

            <!-- <div v-if="hasRole(['SuperAdmin']) && user.currentSetDivision && (!isTabletAndDown)" class="overline" style="width: 1px;">
                <div style="text-align: right; float: right; width: 200px; opacity: .75;" class="pr-6">
                    {{ user.currentSetDivision }} DIVISION
                </div>
            </div> -->

            <div class="q-app-bar__right">
                <!-- Used for opening the right slider if we want it -->
				<!-- <v-btn small text fab @click.stop="toggleRightMenu">
					<v-icon>fas fa-th</v-icon>
				</v-btn> -->

				<QPerformDashBtn v-if="!hasCcsDivision && !isDemoMode" />
                <QSummitOpenSidebarButton v-if="!hasCcsDivision" />
                <QTopMenuSearchBtn id="v-step-2" v-if="!isMobile" />
                <QNotificationMenu id="v-step-3" />
                <QUserMenu id="v-step-3a" :show-avatar="!isMobile" />

                <!---
				<div>
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
						<v-btn @click="startTour" icon v-bind="attrs" v-on="on">
							<i class="fas fa-2x fa-info-circle"></i>
						</v-btn>
						</template>
						<span>Quickly tour available functions </span>
					</v-tooltip>
				</div>
				-->
            </div>
        </div>
    </v-app-bar>
</template>

<script>
import QTopMenuSearchBtn from '../utils/QTopMenuSearchBtn.vue';
import QUserMenu from './QUserMenu';
import QNotificationMenu from './QNotificationMenu';
import QResourceMenuCorporate from './QResourceMenu/QResourceMenuCorporate';
import QResourceMenuField from './QResourceMenu/QResourceMenuField';
import QResourceMenuCss from './QResourceMenu/QResourceMenuCss';
import QSummitOpenSidebarButton from '../summit/sidebar/QSummitOpenSidebarButton.vue';
import QPerformDashBtn from './QPerformDashBtn.vue';
import VerticalLogo from '@/assets/images/QuilityLogoVerticalBlackRGB.svg';
import HorizontalLogo from '@/assets/images/QuilityLogoHorizontal.svg';

export default {
    name: "QAppbar",
    props: ['icon', 'text', 'route', 'click'],

    computed: {
        isMobile() { return this.$vuetify.breakpoint.xs; },
        isTabletAndDown() { return this.$vuetify.breakpoint.smAndDown; },

        logoPath() {
            return this.isMobile
                ? VerticalLogo
                : HorizontalLogo
        },
        hasCcsDivision() {
            return this.hasDivision(['CCS'])
        },
    },

    methods: {
        startTour() {
            this.$refs.tour.startTour(this.$route.name);
        },
        toggleMobileMenu: function() {
            this.$emit('mobileMenu')
        },
        toggleRightMenu: function() {
            this.$emit('rightMenu')
        },
        clicked: function() {
            if (typeof this.route == 'string') {
                this.$router.push({ name: this.route }).catch(err => { this.showError("Whoops! " + err) })
            }
            if (typeof this.click == 'function') {
                this.click();
            }
        }
    },
    components: {
        QTopMenuSearchBtn,
        QUserMenu,
        QNotificationMenu,
        QResourceMenuField,
        QResourceMenuCorporate,
        QResourceMenuCss,
        QSummitOpenSidebarButton,
		QPerformDashBtn,
    }
}
</script>
<style lang="scss" scoped>
.v-tour__target--relative {
    margin-top: 0px !important;
}
</style>

<style lang="scss">
.q-app-bar {
	--height: 72px;
	--width: 60px;

	&--mobile {
		--height: 72px;
		--width: 42px;
	}

	&.v-app-bar {
		color: var(--app-color-secondary) !important;
		background-color: var(--app-color-neutral-4) !important;
	}

	.v-toolbar__content {
		padding: 1rem 1rem;
	}

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

	&__left,
    &__right {
        display: flex;
        justify-content: center;
        align-items: stretch;
        align-self: stretch;

        & > * {
			height: var(--height) !important;
            width: var(--width) !important;
        }
    }

	.nav-logo {
		height: 34px;
		line-height: 1;

        &__demo {
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 600;
            color: #0e0e0e;
        }
	}

	.nav-logo-link {
		display: inline-block;
		height: 34px;
		line-height: 1;
	}

    &--mobile {
		.v-toolbar__content {
			padding: 0.5rem 0.5rem;
		}

        .q-app-bar {
            &__content {
                align-items: flex-end;
            }

            &__left,
            &__right {
                align-items: flex-end;
            }
        }

        .v-btn {
            height: var(--height) !important;
            width: var(--width) !important;
        }
    }

    .v-toolbar__content {
        padding-top: 0;
        padding-bottom: 0;
    }

	.q-app-bar-btn.v-btn {
		height: var(--height) !important;
		width: var(--width) !important;
		min-width: var(--width) !important;
		padding: 0 !important;
		background-color: transparent !important;
		color: black !important;
		transition: background 0.2s, color 0.2s;

		&::before {
			opacity: 1 !important;
			background-color: transparent;
		}

		&.q-app-bar-btn--active:not(:hover),
		&.v-btn--active:not(:hover) {
			background-color: #8D8D8D !important;
			color: white !important;
		}

		&.q-app-bar-btn--accent:not(:hover):not(.q-app-bar-btn--active) {
			background-color: #D0D0D0 !important;
		}

		&.q-app-bar-btn--active:before,
		&.v-btn--active:before {
			border-bottom: 6px solid #505050;
		}

		&:hover {
			background-color: #505050 !important;
			color: white !important;
		}

		&:focus:before,
		&:active:before {
			background-color: transparent;
		}
	}
}
</style>
